.deleteColumn > * {
  float: right;
}

.tooltip {
  position: relative;
  text-decoration: none;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24"><path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0ZM12,8a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,8Z"/></svg>')
    no-repeat;
  background-position: right top;
  padding-right: 18px;
  cursor: pointer;
}

.tooltip:hover::before,
.tooltip.active::before,
.tooltip:focus::before {
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  bottom: 15px;
  content: '';
  border-style: solid;
  border-width: 10px;
  border-color: #000 transparent transparent transparent;
}

.tooltip:hover::after,
.tooltip.active::after,
.tooltip:focus::after {
  position: absolute;
  right: -10px;
  bottom: 35px;
  width: 200px;
  padding: 12px;
  content: attr(data-title);
  font-size: 0.9em;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background: #000;
  color: #fff;
}
