@import 'src/theme';

.spinner {
  margin: 50px auto;
  width: 80px;
  height: 80px;
}

.spinner::after {
  content: ' ';
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 12px solid $textAccentColor;
  border-color: $textAccentColor transparent $textAccentColor transparent;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
