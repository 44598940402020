@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/open-sans-v34-latin-regular.woff2') format('woff2'),
    url('./fonts/open-sans-v34-latin-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/open-sans-v34-latin-700.woff2') format('woff2'),
    url('./fonts/open-sans-v34-latin-700.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Alexandria';
  src: url('./fonts/alexandria-v1-latin-regular.woff2') format('woff2'),
    url('./fonts/alexandria-v1-latin-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Alexandria';
  src: url('./fonts/alexandria-v1-latin-600.woff2') format('woff2'),
    url('./fonts/alexandria-v1-latin-600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Alexandria';
  src: url('./fonts/alexandria-v1-latin-700.woff2') format('woff2'),
    url('./fonts/alexandria-v1-latin-700.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

$backgroundColor: #eaf3f9;
$headerColor: white;
$textColor: black;
$textAccentColor: #484747;

@mixin fontMainTheme {
  font-size: 16px;
  line-height: 24px;
  color: $textColor;
  font-family: 'OpenSans', Verdana, Arial, sans-serif;
}

@mixin fontAccentTheme {
  color: $textAccentColor;
  font-family: 'Alexandria', Verdana, Arial, sans-serif;
}

/* Temporary Yivi web-form styling overrides */
#yivi-web-form {
  background: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 0px;

  .yivi-web-content {
    border-radius: 8px;
    background: #f7f5f3;
  }
}
