@import 'src/theme';

.inputGroup {
  display: flex;
  flex-direction: row;
  justify-items: inherit;
  width: 80%;
  min-width: 262px;
  max-width: 400px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));

  .groupEmail {
    position: relative;
    height: auto;
    flex: 1;
    padding: 10px;
    border-radius: 8px 0 0 8px;
    border: none;
    outline-color: $textAccentColor;
    font-size: 16px;
    line-height: 24px;

    &:focus {
      z-index: 2;
    }
  }

  .groupButton {
    border-radius: 0 8px 8px 0;
  }
}
