@import 'src/theme';

@mixin button {
  width: fit-content;
  padding: 12px 32px;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  outline-color: transparent;
  line-height: 24px;
  font-family: 'OpenSans', Verdana, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.button-primary {
  @include button;
  font-size: 16px;
  color: white;
  background: #e12747 url('../../public/assets/button-primary.svg') no-repeat
    right top;
}

.button-secondary {
  @include button;
  font-size: 16px;
  color: $textAccentColor;
  background: transparent;
  border: 1px solid $textAccentColor;
}

.button-tertiary {
  @include button;
  font-size: 14px;
  border: 1px solid #b6d6ea;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background: #fff;

  &:disabled {
    cursor: not-allowed;
    color: #9f9a9a;
    background: #f7f5f3;
    border-color: #d7d2cd;
  }

  &:not([disabled]):hover {
    background: #eaf3f9;
  }
}

.button-ghost {
  @include button;
  color: #ba3354;
  background: transparent;
  text-decoration: underline;
  font-weight: 700;
  padding: 0px;
  gap: 8px;
  svg {
    height: 16px;
    path {
      stroke: #ba3354;
    }
  }
}

@media (max-width: 600px) {
  /* stylelint-disable-next-line */
  :global(button.responsive) {
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
    padding: 12px 0;

    span {
      display: none;
    }
  }
}
