@import 'src/theme';

html,
body {
  margin: 0;
  padding: 0;
  @include fontMainTheme;
  background-color: $backgroundColor;
}

body {
  padding-bottom: 64px;
}

table th {
  font-weight: normal;
}

h2 {
  @include fontAccentTheme;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 8px 0px;
}

p {
  margin: 16px 0;
}

a {
  color: $textAccentColor;
  text-decoration: underline;
  cursor: pointer;
}
